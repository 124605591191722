
import { format } from 'date-fns'
export default {
  name: 'Report',
  filters: {
    formatDate(val) {
      return format(new Date(val), 'Y-MM-dd hh:mm')
    },
  },
  props: {
    information: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    badge() {
      const list = [
        {
          id: 1,
          title: 'vulnerability.reports.badge_minor',
          color: 'bg-[#FCD34D]',
        },
        {
          id: 2,
          title: 'vulnerability.reports.badge_medium',
          color: 'bg-[#FB923C]',
        },
        {
          id: 3,
          title: 'vulnerability.reports.badge_hight',
          color: 'bg-[#DC2626]',
        },
        {
          id: 4,
          title: 'vulnerability.reports.badge_critical',
          color: 'bg-[#991B1B]',
        },
      ]
      return list.find((item) => item.id === this.information.severity_id)
    },
  },
}
