
import VueScrollTo from 'vue-scrollto'
import { format } from 'date-fns'
import { securityNotificationsQuery } from '@/queries/securityNotifications.gql'
export default {
  name: 'VulnerabilityReport',
  async asyncData({ app, i18n, $config }) {
    try {
      const { reports } = await fetch(
        'https://webapi.teltonika-networks.com/api/vulnerability/reports',
      ).then((res) => res.json())

      const {
        data: { securityNotifications },
      } = await app.$gqlQuery({
        query: securityNotificationsQuery,
        fetchPolicy: 'no-cache',
        variables: {
          domainId: $config.domainId,
          languageCode: i18n.locale,
        },
      })

      let notifications = []
      if (
        securityNotifications?.data &&
        Array.isArray(securityNotifications.data)
      ) {
        notifications = securityNotifications.data.map((notification) => ({
          ...notification,
          date: format(
            new Date(notification.notificationDate),
            'Y-MM-dd hh:mm',
          ),
        }))
      }

      return {
        reports: reports || [],
        initialNotifications: notifications,
      }
    } catch {
      return { reports: [], initialNotifications: [] }
    }
  },
  data() {
    return {
      reports: [],
      showPagination: true,
      page: 1,
      limitItems: 6,
      typeInputValue: '',
      typeInputOptions: [],
      typeInputOptionsSelected: [],
      productInputValue: '',
      productInputOptions: [],
      productInputOptionsSelected: [],
      timeout: null,
      searchInputValueDebounced: '',
      showVulnerabilityForm: false,
      pageData: {},
      isNotificationsExpanded: false,
      notificationSearchValue: '',
      activeNotifications: {},
      searchResultsCount: 0,
    }
  },
  computed: {
    searchInputValue: {
      get() {
        return this.searchInputValueDebounced
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.searchInputValueDebounced = val
        }, 300)
      },
    },
    visibleReports() {
      let reports = this.reports
      if (this.typeInputOptionsSelected.length) {
        reports = reports.filter((item) =>
          this.typeInputOptionsSelected.some((type) =>
            item.product.includes(type),
          ),
        )
      }
      if (this.productInputOptionsSelected.length) {
        reports = reports.filter((item) =>
          this.productInputOptionsSelected.some((type) =>
            item.model.includes(type),
          ),
        )
      }
      if (this.searchInputValue.length) {
        reports = reports.filter((item) =>
          item.title.includes(this.searchInputValue),
        )
      }
      return reports.slice(0, this.page * this.limitItems)
    },
    heroNavigationItems() {
      return [
        {
          sectionId: '#reportVulnerabilitySection',
          label: this.$i18n.t('vulnerability.hero.link1.text'),
        },
        {
          sectionId: '#reportsSection',
          label: this.$i18n.t('vulnerability.hero.link2.text'),
        },
        {
          sectionId: '#securityDeclarationSection',
          label: this.$i18n.t('vulnerability.hero.link3.text'),
        },
      ]
    },
    bannerBackgroundImage() {
      return this.pageData?.files?.['vulnerability.banner.background.image']
    },
    notifications() {
      if (
        !this.notificationSearchValue ||
        this.notificationSearchValue.length < 2
      )
        return this.initialNotifications

      return this.initialNotifications.map((notification) => ({
        ...notification,
        titleTranslated: this.highlightSearchedText(
          notification.titleTranslated,
          notification.id,
        ),
        descriptionTranslated: this.highlightSearchedText(
          notification.descriptionTranslated,
          notification.id,
        ),
        date: this.highlightSearchedText(notification.date, notification.id),
      }))
    },
  },
  watch: {
    typeInputValue(newVal) {
      this.addDropdownOption(newVal, 'type')
    },
    productInputValue(newVal) {
      this.addDropdownOption(newVal, 'product')
    },
    showVulnerabilityForm(newValue, oldValue) {
      if (newValue === oldValue) return
      document.body.classList.toggle('overflow-hidden')
    },
    notificationSearchValue() {
      this.searchResultsCount = 0
    },
  },
  created() {
    this.pageData = this.$store.state.page.content
  },
  mounted() {
    if (this.reports.length) {
      this.typeInputOptions = this.setDropdownOptions('product')
      this.productInputOptions = this.setDropdownOptions('model')
    }
  },
  methods: {
    highlightSearchedText(text, id) {
      const node = new DOMParser().parseFromString(text, 'text/html').body
      this.replaceNodes(node, id)

      return node.innerHTML
    },
    replaceNodes(node, id) {
      const replaceWithNewNode = (text) => {
        const span = document.createElement('span')
        span.className = 'font-bold text-blue-800'
        span.innerHTML = text
        return span
      }
      if (node.nodeType === Node.TEXT_NODE) {
        const regex = new RegExp(this.notificationSearchValue, 'gi')
        const parts = node.textContent.split(regex)

        if (parts.length > 1) {
          const fragment = document.createDocumentFragment()
          let lastIndex = 0

          node.textContent.replace(regex, (match, index) => {
            fragment.appendChild(
              document.createTextNode(node.textContent.slice(lastIndex, index)),
            )
            fragment.appendChild(replaceWithNewNode(match))
            lastIndex = index + match.length

            this.searchResultsCount++
          })

          fragment.appendChild(
            document.createTextNode(node.textContent.slice(lastIndex)),
          )
          node.parentNode.replaceChild(fragment, node)

          this.activeNotifications[id] = true
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach((item) =>
          this.replaceNodes(item, id),
        )
      }
    },
    scrollTo(sectionId) {
      VueScrollTo.scrollTo(sectionId, 600)
    },
    removeDropdownOption(item, name) {
      this[`${name}InputOptionsSelected`] = this[
        `${name}InputOptionsSelected`
      ].filter((el) => el !== item)
      this.$refs[`${name}Select`].setSelectedOption({
        text: '',
        value: '',
      })
    },
    addDropdownOption(item, name) {
      if (!this[`${name}InputOptionsSelected`].includes(item)) {
        this[`${name}InputOptionsSelected`] = [
          ...this[`${name}InputOptionsSelected`],
          item,
        ]
      }
    },
    setDropdownOptions(field) {
      const items = this.reports?.map((item) => item[field].split(','))
      const itemsMerged = items
        .reduce((a, b) => a.concat(b), [])
        .map((item) => item.trim())
      const itemsFiltered = [...new Set(itemsMerged)]
      const itemsFormatted = itemsFiltered.map((item) => ({
        text: item,
        value: item,
      }))
      return itemsFormatted
    },
    loadMore() {
      setTimeout(() => {
        this.page++
        if (this.page * this.limitItems >= this.reports.length) {
          this.showPagination = false
        }
      }, 500)
    },
  },
}
