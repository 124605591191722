
export default {
  name: 'HeroTwentyThree',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    backgroundImage: {
      type: Object,
      default: () => {},
    },
    navigationItems: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    image() {
      const imgUrl = this.$store.getters['resize/isMobile']
        ? '/images/hero-gpl-mobile.webp'
        : '/images/hero-gpl.webp'
      return {
        backgroundImage: `url('${imgUrl}')`,
      }
    },
    gradient() {
      const gradient = this.$store.getters['resize/isMobile']
        ? 'linear-gradient(180deg,rgba(39, 49, 58, 0.5) 0%,rgba(39, 48, 59, 0) 100%)'
        : 'linear-gradient(90deg,rgba(0, 0, 0, 0.65) 0%,rgba(25, 30, 37, 0.63) 36.56%,rgba(39, 48, 59, 0.12) 57.53%)'
      return { background: gradient }
    },
  },
}
