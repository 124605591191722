import { render, staticRenderFns } from "./vulnerability-report.vue?vue&type=template&id=3dc86092&scoped=true"
import script from "./vulnerability-report.vue?vue&type=script&lang=js"
export * from "./vulnerability-report.vue?vue&type=script&lang=js"
import style0 from "./vulnerability-report.vue?vue&type=style&index=0&id=3dc86092&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@3.2.5_vue-temp_n4gdrmfirpmgaryqotnkfbokze/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dc86092",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormGenerator: require('/src/components/form/generator/index.vue').default,HeroTwentyThree: require('/src/components/hero/HeroTwentyThree.vue').default,VulnerabilityReportSearch: require('/src/components/VulnerabilityReportSearch.vue').default,ViewMoreLessByLines: require('/src/components/ViewMoreLessByLines.vue').default,SectionClampHtmlText: require('/src/components/section/ClampHtmlText.vue').default,CardsGraySimpleSlotCard: require('/src/components/cards/GraySimpleSlotCard.vue').default,ProcessSvg: require('/src/components/ProcessSvg.vue').default,Button: require('/src/components/Button.vue').default,FormSelect: require('/src/components/form/Select.vue').default,FormInput: require('/src/components/form/Input.vue').default,CardsReport: require('/src/components/cards/Report.vue').default,InfinityLoader: require('/src/components/InfinityLoader.vue').default})
